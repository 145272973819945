import React, { useState, useEffect } from "react";
import "./Home.css";
import { Navbar, Container, Nav, Button, Col, Card, Row } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Logo from "../src/Photos/Deepa Sphere Soln Logo.png";
import hometopimage from "../src/Photos/Deepa Sphere Solutions Assets/hometopimage.png";
import homepageaboutus from "../src/Photos/Deepa Sphere Solutions Assets/homepageaboutus.jpg";
import image1 from "../src/Photos/Deepa Sphere Solutions Assets/image 1.svg";
import image2 from "../src/Photos/Deepa Sphere Solutions Assets/image 2.svg";
import image3 from "../src/Photos/Deepa Sphere Solutions Assets/image 3.svg";
import image4 from "../src/Photos/Deepa Sphere Solutions Assets/image 4.svg";
import image5 from "../src/Photos/Deepa Sphere Solutions Assets/image 5.svg";
// import call from "../src/Photos/Deepa Sphere Solutions Assets/Frame-1.svg";
// // import mail from "../src/Photos/Deepa Sphere Solutions Assets/Frame.svg";
// import whatsapp from "../src/Photos/Deepa Sphere Solutions Assets/whatsapp_symbol.svg.svg";
// import instagram from "../src/Photos/Deepa Sphere Solutions Assets/Logo.svg.svg";
// import linkedin from "../src/Photos/Deepa Sphere Solutions Assets/linkedin_symbol.svg.svg";
import screen1 from "../src/Photos/Deepa Sphere Solutions Assets/Home Screen.png";
import screen4 from "../src/Photos/Deepa Sphere Solutions Assets/Mattress Bazar.png";

import MattressBazar from "../src/Photos/Mattress Bazar.png";
import Eximtrac from "../src/Photos/Eximtrac.jpg";
import ChiragEnterprises from "../src/Photos/Chirag Enterprises.png";
import DeepaliGroup from "../src/Photos/Deepali Group Of Music.jpg";


import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";



import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {

    const stats = [
        { label: "Since In Business", value: 2023, showPlus: false },
        { label: "Happy Clients", value: 5, showPlus: true },
        { label: "Projects Delivered", value: 6, showPlus: true },
    ];

    const StatItem = ({ value, label, showPlus }) => {
        const [count, setCount] = useState(0);

        useEffect(() => {
            let start = 0;
            const end = value;
            const duration = 1000; // Duration of animation (1 second)
            const increment = end / (duration / 10); // Calculate the increment value

            const timer = setInterval(() => {
                start += increment;
                if (start >= end) {
                    start = end;
                    clearInterval(timer);
                }
                setCount(Math.ceil(start));
            }, 10); // Update every 10ms

            return () => clearInterval(timer);
        }, [value]);

        return (
            <Col md={4} className="stat-item">
                <h2 className="stat-value">
                    {count}
                    {showPlus && "+"}
                </h2>
                <p className="stat-label">{label}</p>
            </Col>
        );
    };



    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };



    const testimonials = [
        {
            id: 1,
            name: "John Doe",
            position: "CEO, Company A",
            quote: "This company provided excellent service, exceeding our expectations at every step of the way.",
            image: "/path/to/image1.jpg" // Add path to the client's image
        },
        {
            id: 2,
            name: "Jane Smith",
            position: "Marketing Director, Company B",
            quote: "We were thrilled with the results. The team is highly professional and skilled.",
            image: "/path/to/image2.jpg"
        },
        {
            id: 3,
            name: "Michael Johnson",
            position: "Product Manager, Company C",
            quote: "Their attention to detail and dedication to our project was outstanding.",
            image: "/path/to/image3.jpg"
        },
        // Add more testimonials as needed
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top companylogo" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="homebtn">
                                <Nav.Link as={Link} to="/" className="hometext mx-3">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="aboutbtn">
                                <Nav.Link as={Link} to="/About" className="abouttext mx-3">About</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="servicebtn">
                                <Nav.Link as={Link} to="/Services" className="servicetext mx-3">Services</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="workbtn">
                                <Nav.Link as={Link} to="/Work" className="worktext mx-3">Work</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="contactbtn m-0 p-0">
                                <Nav.Link as={Link} to="/Contactus" className="contacttext mx-2">Contact Us</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col lg={6} xs={7} className="p-0 mt-4">
                        <Card className="pt-5 px-3 py-5 hometopcard">
                            <p className="hometoptext">Innovative Solutions <span className="for">for</span></p>
                            <p className="hometoptext2">Modern Businesses</p>
                            <p className="hometoptext3">We specialize in creating custom software solutions to help your business thrive.</p>
                        </Card>
                    </Col>

                    <Col lg={6} xs={5} className="p-0 mt-0">
                        <Card className="hometopcard2">
                            <img src={hometopimage}
                                alt=""
                                className="d-inline-block align-top hometopimage"
                            />
                        </Card>
                    </Col>
                </Row>

                <Row className="px-5">
                    <Col lg={6} xs={12}>
                        <Card className="aboutusimagecard">
                            <Card.Img variant="top" src={homepageaboutus} className="image-container" />
                        </Card>
                    </Col>

                    <Col className="about-us-container" lg={6} xs={12}>
                        <Card.Body>
                            <Card.Title className="homeaboutustext2">
                                About <span className="us">Us</span>
                            </Card.Title>

                            <Card.Text className="homeaboutustext mt-4">
                                At Deepa Sphere Solutions, we specialize in transforming ideas into innovative digital experiences.
                                <br /><br />
                                Our expertise spans software development, mobile app creation, website and web-app development, as well as cutting-edge UI/UX design.
                                <br /><br />
                                We are dedicated to crafting solutions that are not only technically robust but also intuitively user-friendly, ensuring that our clients stand out in the digital landscape.
                                <br /><br />
                                With a focus on quality, creativity, and excellence, we partner with businesses to bring their visions to life, driving growth and success in an increasingly connected world.
                            </Card.Text>

                            <Button onClick={scrollToTop} className="knowmorebtn">
                                <Link to="/About" className="knowmorelink">
                                    Know More
                                </Link>
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>


                {/* <div className="about-us-container">
                    <div className="image-container">
                        <img src={homepageaboutus} alt="About Us" />
                    </div>
                    <div className="text-container">
                        <h2>About <span className="highlight">Us</span></h2>
                        <p>
                            Lorem ipsum dolor sit amet consectetur. Egestas ut a congue eu turpis dictum
                            vulputate proin sit. Sed blandit diam luctus pellentesque et posuere tristique
                            dignissim. Odio tellus euismod ullamcorper feugiat.
                        </p>
                        <button className="know-more-btn">Know More</button>
                    </div>
                </div> */}

                <Row>
                    <Col className="mt-5 text-center">
                        <p className="home-our-services">Our <span className="service">Services</span></p>
                    </Col>
                </Row>

                <Row className="">
                    <Col className="mt-3" lg={4} xs={12}>
                        <Button onClick={scrollToTop} className="w-100 home-services-card-button">
                            <Link to="/Services" className="home-services-card-link">
                                <Card className="home-services-card">
                                    <Row>
                                        <Col className="text-center mt-3">
                                            <img src={image1}
                                                alt=""
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-3">
                                            <p>App Development</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3" lg={4} xs={12}>
                        <Button onClick={scrollToTop} className="w-100 home-services-card-button">
                            <Link to="/Services" className="home-services-card-link">
                                <Card className="home-services-card">
                                    <Row>
                                        <Col className="text-center mt-3">
                                            <img src={image2}
                                                alt=""
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-3">
                                            <p>Website Development</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3" lg={4} xs={12}>
                        <Button onClick={scrollToTop} className="w-100 home-services-card-button">
                            <Link to="/Services" className="home-services-card-link">
                                <Card className="home-services-card">
                                    <Row>
                                        <Col className="text-center mt-3">
                                            <img src={image3}
                                                alt=""
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-3">
                                            <p>Web App Development</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col className="mt-3" lg={4} xs={12}>
                        <Button onClick={scrollToTop} className="w-100 home-services-card-button">
                            <Link to="/Services" className="home-services-card-link">
                                <Card className="home-services-card">
                                    <Row>
                                        <Col className="text-center mt-3">
                                            <img src={image5}
                                                alt=""
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-3">
                                            <p>Software Development</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3" lg={4} xs={12}>
                        <Button onClick={scrollToTop} className="w-100 home-services-card-button">
                            <Link to="/Services" className="home-services-card-link">
                                <Card className="home-services-card">
                                    <Row>
                                        <Col className="text-center mt-3">
                                            <img src={image4}
                                                alt=""
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-3">
                                            <p>UI/UX Design</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Container fluid className="stats-section mt-5">
                    <Row className="text-center">
                        {stats.map((stat, index) => (
                            <StatItem
                                key={index}
                                value={stat.value}
                                label={stat.label}
                                showPlus={stat.showPlus}
                            />
                        ))}
                    </Row>
                </Container>

                <Row>
                    <Col className="mt-5 text-center">
                        <p className="home-our-work">Our <span className="works">Work</span></p>
                    </Col>
                </Row>

                <Container>
                    <Row>
                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen4}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Mattress Bazar</Card.Title>
                                    <Card.Text className="nametitle2">
                                        Sleeping Expert.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen1}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Chirag Enterprises</Card.Title>
                                    <Card.Text className="nametitle2">
                                        To make your Accounting Easy.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-3 text-center">
                            <Button onClick={scrollToTop} className="home-work-btn">
                                <Link to="/Work" className="home-work-link">
                                    Explore All Work
                                </Link>
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <Row>
                    <Col className="text-center mt-5">
                        <p className="home-clients">Our <span className="client">Clients</span></p>
                    </Col>
                </Row>

                <Row>
                    <Col className="py-3">
                        <div className="slider">
                            <div className="slider-track">
                                <div className="slider-item">
                                    <img src={ChiragEnterprises} alt="Brand 1" className="imagesize1" />
                                </div>
                                <div className="slider-item">
                                    <img src={MattressBazar} alt="Brand 2" className="imagesize2" />
                                </div>
                                <div className="slider-item">
                                    <img src={Eximtrac} alt="Brand 3" className="imagesize3" />
                                </div>
                                <div className="slider-item">
                                    <img src={DeepaliGroup} alt="Brand 4" className="imagesize4" />
                                </div>
                                {/* Repeat for more logos */}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center mt-5">
                        <p className="home-testimonials">What Our Clients Say</p>
                    </Col>
                </Row>

                <Container className="testimonials-section">
                    <Slider {...settings}>
                        {testimonials.map((testimonial) => (
                            <div key={testimonial.id}>
                                <Row className="justify-content-center">
                                    <Col md={8}>
                                        <Card className="testimonial-card">
                                            <Card.Body>
                                                <div className="d-flex align-items-center mb-4">
                                                    <img src={testimonial.image} alt={testimonial.name} className="testimonial-image mr-3" />
                                                    <div>
                                                        <h5 className="mb-1">{testimonial.name}</h5>
                                                        <p className="mb-0">{testimonial.position}</p>
                                                    </div>
                                                </div>
                                                <Card.Text className="testimonial-quote">
                                                    "{testimonial.quote}"
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Slider>
                </Container>

                <Row>
                    <Col className="text-center">
                        <Card className="pic">
                            <p className="home-contact-text mt-5 pt-5">Let's Start a Conversation</p>
                            <br />
                            <p className="home-contact-text2 px-5 mx-3">We'd love to hear from you! Whether you have a question about our services, need assistance with a project, or want
                                to explore how we can collaborate, our team is here to help.</p>
                            <Row>
                                <Col className="">
                                    <Button onClick={scrollToTop} className="mt-4 mb-5 home-contact-btn">
                                        <Link to="/Contactus" className="home-contact-link">
                                            Contact Us
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/* ----------------------Footer---------------------- */}

                <MDBFooter bgColor='white' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Deepa Sphere Solutions
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top companylogo"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/" className='text-reset footer-navi-link'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/About" className='text-reset footer-navi-link'>
                                                About
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Services" className='text-reset footer-navi-link'>
                                                Services
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Work" className='text-reset footer-navi-link'>
                                                Work
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:8591188706">
                                                <FiPhoneCall className="me-3" /> +91 85911-88706
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                <MdMailOutline className="me-3" /> deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Connect with us</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>

                                    {/* <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="">
                                                <FaInstagram />
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="tel:9324053215">
                                                <FaLinkedin />
                                            </a>
                                        </div>
                                    </p> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                </MDBFooter>
            </Container >
        </>
    );
}

export default Home;