import React from "react";
import "./Work.css";
import { Navbar, Container, Nav, Button, Col, Card, Row } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Logo from "../src/Photos/Deepa Sphere Soln Logo.png";
import screen1 from "../src/Photos/Deepa Sphere Solutions Assets/Home Screen.png";
import screen2 from "../src/Photos/Deepa Sphere Solutions Assets/Admin Dashboard.png";
import screen3 from "../src/Photos/Deepa Sphere Solutions Assets/Home.png";
import screen4 from "../src/Photos/Deepa Sphere Solutions Assets/Mattress Bazar.png";

import MattressBazar from "../src/Photos/Mattress Bazar.png";
import Eximtrac from "../src/Photos/Eximtrac.jpg";
import ChiragEnterprises from "../src/Photos/Chirag Enterprises.png";
import DeepaliGroup from "../src/Photos/Deepali Group Of Music.jpg";


import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";


function Work() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top companylogo" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="homebtn">
                                <Nav.Link as={Link} to="/" className="hometext2 mx-3">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="aboutbtn">
                                <Nav.Link as={Link} to="/About" className="abouttext3 mx-3">About</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="servicebtn">
                                <Nav.Link as={Link} to="/Services" className="servicetext3 mx-3">Services</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="workbtn">
                                <Nav.Link as={Link} to="/Work" className="worktext1 mx-3">Work</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="contactbtn m-0 p-0">
                                <Nav.Link as={Link} to="/Contactus" className="contacttext mx-2">Contact Us</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="work-pic text-center">
                        <p className="work">Our Work</p>
                    </Col>
                </Row>

                <Container>
                    <Row>
                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen1}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Chirag Enterprises</Card.Title>
                                    <Card.Text className="nametitle2">
                                        To make your Accounting Easy.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen2}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Eximtrac</Card.Title>
                                    <Card.Text className="nametitle2">
                                        Exporting Swiftly.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen3}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Automatic Tiles & Marbles Pvt. Ltd.</Card.Title>
                                    <Card.Text className="nametitle2">
                                        Beautify Your Home.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mt-4" lg={6} xs={12}>
                            <Card className="card-hover">
                                <Card.Img
                                    src={screen4}
                                    alt="Card image"
                                />
                                <Card.Body className="text-center">
                                    <Card.Title className="nametitle">Mattress Bazar</Card.Title>
                                    <Card.Text className="nametitle2">
                                        Sleeping Expert.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Row>
                    <Col className="text-center mt-5">
                        <p className="home-clients">Our <span className="client">Clients</span></p>
                    </Col>
                </Row>

                <Row>
                    <Col className="py-3">
                        <div className="slider">
                            <div className="slider-track">
                                <div className="slider-item">
                                    <img src={ChiragEnterprises} alt="Brand 1" className="imagesize1" />
                                </div>
                                <div className="slider-item">
                                    <img src={MattressBazar} alt="Brand 2" className="imagesize2" />
                                </div>
                                <div className="slider-item">
                                    <img src={Eximtrac} alt="Brand 3" className="imagesize3" />
                                </div>
                                <div className="slider-item">
                                    <img src={DeepaliGroup} alt="Brand 4" className="imagesize4" />
                                </div>
                                {/* Repeat for more logos */}
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* ----------------------Footer---------------------- */}

                <MDBFooter bgColor='white' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Deepa Sphere Solutions
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top companylogo"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/" className='text-reset footer-navi-link'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/About" className='text-reset footer-navi-link'>
                                                About
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Services" className='text-reset footer-navi-link'>
                                                Services
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Work" className='text-reset footer-navi-link'>
                                                Work
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:8591188706">
                                                <FiPhoneCall className="me-3" /> +91 85911-88706
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                <MdMailOutline className="me-3" /> deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Connect with us</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>

                                    {/* <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="">
                                                <FaInstagram />
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="tel:9324053215">
                                                <FaLinkedin />
                                            </a>
                                        </div>
                                    </p> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                </MDBFooter>
            </Container>
        </>
    );
}

export default Work;