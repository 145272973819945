import React from "react";
import "./Services.css";
import { Navbar, Container, Nav, Button, Col, Card, Row } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Logo from "../src/Photos/Deepa Sphere Soln Logo.png";
import app from "../src/Photos/Deepa Sphere Solutions Assets/app.jpg";
import website from "../src/Photos/Deepa Sphere Solutions Assets/website.jpg";
import webapp from "../src/Photos/Deepa Sphere Solutions Assets/webapp.jpg";
import software from "../src/Photos/Deepa Sphere Solutions Assets/software.jpg";
import uiux from "../src/Photos/Deepa Sphere Solutions Assets/uiux.jpg";


import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";


function Services() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top companylogo" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="homebtn">
                                <Nav.Link as={Link} to="/" className="hometext2 mx-3">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="aboutbtn">
                                <Nav.Link as={Link} to="/About" className="abouttext3 mx-3">About</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="servicebtn">
                                <Nav.Link as={Link} to="/Services" className="servicetext2 mx-3">Services</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="workbtn">
                                <Nav.Link as={Link} to="/Work" className="worktext mx-3">Work</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="contactbtn m-0 p-0">
                                <Nav.Link as={Link} to="/Contactus" className="contacttext mx-2">Contact Us</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="service-pic text-center">
                        <p className="services">Our Services</p>
                    </Col>
                </Row>

                <Container>
                    <Row>
                        <Col className="mt-4">
                            <Card className="appdevmaincard">
                                <Row>
                                    <Col lg={4}>
                                        <Card className="appdevcard">
                                            <img src={app}
                                                alt="app"
                                                className="d-inline-block align-top appdevimage"
                                            />
                                        </Card>
                                    </Col>

                                    <Col className="px-5 mt-4">
                                        <p className="appdev">App Development</p>
                                        <p className="appdevtext">
                                            We offer comprehensive app development services to bring your ideas to life. Our team specializes in creating robust, user-friendly apps that cater to various business needs. Whether you're looking for a native app or a cross-platform solution, we've got you covered.
                                        </p>
                                        <p className="appdevtext2"><span className="ps">Platforms Supported:</span> iOS, Android, Cross-Platform (React Native, Flutter)</p>
                                        <p className="appdevtext2"><span className="ps">Technologies Used:</span> Swift, Kotlin, Java, React Native, Flutter</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4">
                            <Card className="webmaincard">
                                <Row>
                                    <Col className="px-5 mt-4">
                                        <p className="web">Website Development</p>
                                        <p className="webtext">
                                            We provide end-to-end website development services, crafting responsive and aesthetically pleasing websites that align with your brand identity. Our expertise covers a wide range of website types, tailored to meet specific business objectives.
                                        </p>
                                        <p className="webtext2"><span className="type">Types of Websites:</span> E-commerce, Corporate, Personal Blogs, Portfolio, Landing Pages</p>
                                        <p className="webtext2"><span className="type">Technologies Used:</span> HTML, CSS, JavaScript, WordPress, Shopify, WooCommerce, PHP, React, Angular</p>
                                    </Col>

                                    <Col lg={4}>
                                        <Card className="webcard">
                                            <img src={website}
                                                alt="app"
                                                className="d-inline-block align-top webimage"
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4">
                            <Card className="webappmaincard">
                                <Row>
                                    <Col lg={4}>
                                        <Card className="webappcard">
                                            <img src={webapp}
                                                alt="app"
                                                className="d-inline-block align-top webappimage"
                                            />
                                        </Card>
                                    </Col>

                                    <Col className="px-5 mt-4">
                                        <p className="webapp">Web App Development</p>
                                        <p className="webapptext">
                                            Our web app development services focus on building powerful and scalable web applications that provide a rich user experience. We leverage modern technologies to create web apps that are accessible from any device with a browser.
                                        </p>
                                        <p className="webapptext2"><span className="ps">Benefits of Web Apps:</span> Accessibility, Cross-Platform Compatibility, Easy Maintenance, Scalability</p>
                                        <p className="webapptext2"><span className="ps">Technologies Used:</span> HTML, CSS, JavaScript, WordPress, Shopify, WooCommerce, PHP,
                                            Node.js, Mongo DB, SQL, React, Angular, Vue.js</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4">
                            <Card className="softmaincard">
                                <Row>
                                    <Col className="px-5 mt-4">
                                        <p className="soft">Software Development</p>
                                        <p className="softtext">
                                        </p>
                                        <p className="softtext2"><span className="typee">Platforms Supported:</span> Comprehensive development across all major platforms, including web, mobile (iOS, Android), desktop (Windows, macOS, Linux), cloud-based solutions, and embedded systems. </p>
                                        <p className="softtext2"><span className="typee">Technologies Used:</span> HTML5, CSS3, JavaScript, TypeScript, Python, Java, Node.js, React, Angular, Flutter, Laravel, MySQL, MongoDB</p>
                                    </Col>

                                    <Col lg={4}>
                                        <Card className="softcard">
                                            <img src={software}
                                                alt="app"
                                                className="d-inline-block align-top softimage"
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4">
                            <Card className="uiuxmaincard">
                                <Row>
                                    <Col lg={4}>
                                        <Card className="uiuxcard">
                                            <img src={uiux}
                                                alt="app"
                                                className="d-inline-block align-top uiuximage"
                                            />
                                        </Card>
                                    </Col>

                                    <Col className="px-5 mt-4">
                                        <p className="uiux">UI/UX Designing</p>
                                        <p className="uiuxtext">
                                            We offer comprehensive app development services to bring your ideas to life. Our team specializes in creating robust, user-friendly apps that cater to various business needs. Whether you're looking for a native app or a cross-platform solution, we've got you covered.
                                        </p>
                                        <p className="uiuxtext2"><span className="process">Design Process Overview:</span> Research & Analysis, Wireframing, Prototyping,
                                            Visual Design, User Testing</p>
                                        <p className="uiuxtext2"><span className="process">Tools and Techniques Used:</span> Adobe XD, Figma, Sketch, InVision, Zeplin</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* ----------------------Footer---------------------- */}

                <MDBFooter bgColor='white' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Deepa Sphere Solutions
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top companylogo"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/" className='text-reset footer-navi-link'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/About" className='text-reset footer-navi-link'>
                                                About
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Services" className='text-reset footer-navi-link'>
                                                Services
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Work" className='text-reset footer-navi-link'>
                                                Work
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:8591188706">
                                                <FiPhoneCall className="me-3" /> +91 85911-88706
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                <MdMailOutline className="me-3" /> deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Connect with us</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>

                                    {/* <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="">
                                                <FaInstagram />
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="tel:9324053215">
                                                <FaLinkedin />
                                            </a>
                                        </div>
                                    </p> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                </MDBFooter>
            </Container>
        </>
    );
}

export default Services;