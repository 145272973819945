import React from "react";
import "./Contactus.css";
import { Navbar, Container, Nav, Button, Col, Card, Row, Form } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Logo from "../src/Photos/Deepa Sphere Soln Logo.png";
import contactpic from "../src/Photos/Deepa Sphere Solutions Assets/hometopimage.png";
import contact from "../src/Photos/Deepa Sphere Solutions Assets/Contact Us.svg";
import mail from "../src/Photos/Deepa Sphere Solutions Assets/Mail Us.svg";
import connect from "../src/Photos/Deepa Sphere Solutions Assets/Connect With Us.svg";


import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";

function Contactus() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top companylogo" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="homebtn">
                                <Nav.Link as={Link} to="/" className="hometext2 mx-3">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="aboutbtn">
                                <Nav.Link as={Link} to="/About" className="abouttext3 mx-3">About</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="servicebtn">
                                <Nav.Link as={Link} to="/Services" className="servicetext3 mx-3">Services</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="workbtn">
                                <Nav.Link as={Link} to="/Work" className="worktext2 mx-3">Work</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="contactbtn m-0 p-0">
                                <Nav.Link as={Link} to="/Contactus" className="contacttext mx-2">Contact Us</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container>
                <Row>
                    <Col lg={6} className="p-0 mt-3">
                        <Card className="contactleft">
                            <img src={contactpic}
                                alt="contact"
                                className="p-5 mt-5"
                            />
                        </Card>
                    </Col>

                    <Col lg={6} className="p-0 mt-3">
                        <Card className="p-5 contactrightcard">
                            <p className="contactright">Let's level up your brand, together</p>
                            <Form>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your Name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="tel" placeholder="Your Phone Number" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>How can we help?</Form.Label>
                                    <Form.Control as="textarea" rows={3} />
                                </Form.Group>

                                <Button className="w-100 contactbtn">Get Started</Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4} xs={12} className="mt-5">
                        <Card className="contactbottomcard">
                            <Row>
                                <Col className="text-center mt-5">
                                    <img src={contact}
                                        alt=""
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <p className="contactbtn1">Contact Us</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center mb-4">
                                    <p className="contactbtn2">
                                        <div>
                                            <a href="tel:8591188706">
                                                +91 85911-88706
                                            </a>
                                        </div>
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col lg={4} xs={12} className="mt-5">
                        <Card className="contactbottomcard">
                            <Row>
                                <Col className="text-center mt-5">
                                    <img src={mail}
                                        alt=""
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <p className="contactbtn1">Mail Us</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center mb-4">
                                    <p className="contactbtn2">
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col lg={4} xs={12} className="mt-5">
                        <Card className="contactbottomcard">
                            <Row>
                                <Col className="text-center mt-5">
                                    <img src={connect}
                                        alt=""
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <p className="contactbtn1">Connect With Us</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center mb-4">
                                    <p className="contactbtn2">
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/* ----------------------Footer---------------------- */}

                <MDBFooter bgColor='white' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Deepa Sphere Solutions
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top companylogo"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/" className='text-reset footer-navi-link'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/About" className='text-reset footer-navi-link'>
                                                About
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Services" className='text-reset footer-navi-link'>
                                                Services
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Work" className='text-reset footer-navi-link'>
                                                Work
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:8591188706">
                                                <FiPhoneCall className="me-3" /> +91 85911-88706
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                <MdMailOutline className="me-3" /> deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Connect with us</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>

                                    {/* <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="">
                                                <FaInstagram />
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="tel:9324053215">
                                                <FaLinkedin />
                                            </a>
                                        </div>
                                    </p> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                </MDBFooter>
            </Container >
        </>
    );
}

export default Contactus;