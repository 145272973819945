import React from "react";
import "./About.css";
import { Navbar, Container, Nav, Button, Col, Card, Row } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Logo from "../src/Photos/Deepa Sphere Soln Logo.png";
import vision from "../src/Photos/Deepa Sphere Solutions Assets/Frame 16.svg";
import mission from "../src/Photos/Deepa Sphere Solutions Assets/Frame 16-1.svg";
import innovation from "../src/Photos/Deepa Sphere Solutions Assets/image 7.svg";
import quality from "../src/Photos/Deepa Sphere Solutions Assets/image 8.svg";
import integrity from "../src/Photos/Deepa Sphere Solutions Assets/image 9.svg";
import customerfocus from "../src/Photos/Deepa Sphere Solutions Assets/image 11.svg";
import collaboration from "../src/Photos/Deepa Sphere Solutions Assets/image 12.svg";


import { FiPhoneCall } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";

function About() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top companylogo" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="homebtn">
                                <Nav.Link as={Link} to="/" className="hometext2 mx-3">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="aboutbtn">
                                <Nav.Link as={Link} to="/About" className="abouttext2 mx-3">About</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="servicebtn">
                                <Nav.Link as={Link} to="/Services" className="servicetext mx-3">Services</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="workbtn">
                                <Nav.Link as={Link} to="/Work" className="worktext mx-3">Work</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="contactbtn m-0 p-0">
                                <Nav.Link as={Link} to="/Contactus" className="contacttext mx-2">Contact Us</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="about-pic text-center">
                        <p className="aboutus">About Us</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center mt-5">
                        <Card className="about-top-card">
                            <p className="about-top-text">Transforming Innovative Ideas <span className="about-top-text2">Into Powerful Digital Solutions.</span></p>
                            <Row>
                                <Col className="px-5">
                                    <p className="about-top mt-4">
                                        At Deepa Sphere Solutions, innovation is at the core of everything we do. We take your ideas and transform them into powerful digital solutions that drive real-world impact.
                                        <br />Whether it's developing sophisticated software, crafting intuitive mobile apps, or designing visually stunning and user-friendly websites, we are committed to delivering products that not only meet but exceed expectations.
                                        <br />Our multidisciplinary team of experts works closely with you to understand your vision, leveraging the latest technologies and design principles to bring your concepts to life.
                                        <br />By combining creativity, technical expertise, and a deep understanding of user experience, we ensure that every project we undertake is a step forward in the digital age.
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-5 px-4" lg={6} xs={12}>
                        <Card className="visioncard">
                            <Row className="px-4 pt-4">
                                <Col lg={1} xs={2}>
                                    <img src={vision}
                                        alt="vision"
                                        className="d-inline-block align-top vision"
                                    />
                                </Col>

                                <Col className="mt-1 mx-3">
                                    <p className="visiontext">Our <span className="vision-col">Vision</span></p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="px-5 py-4">
                                    <p>Our vision is to be a global leader in digital innovation, creating transformative solutions that enhance the way businesses operate.
                                        <br /><br />We aspire to shape the future of digital experiences, making technology more accessible and impactful for all.
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-5 px-4" lg={6} xs={12}>
                        <Card className="missioncard">
                            <Row className="px-4 pt-4">
                                <Col lg={1} xs={2}>
                                    <img src={mission}
                                        alt="mission"
                                        className="d-inline-block align-top mission"
                                    />
                                </Col>

                                <Col className="mt-1 mx-3">
                                    <p className="missiontext">Our <span className="mission-col">Mission</span></p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="px-5 py-4">
                                    <p>Our mission at Deepa Sphere Solutions is to empower businesses by delivering innovative digital solutions that transform ideas into reality.
                                        <br />We focus on creating high-quality software, mobile apps, web applications, and UI/UX designs that are both functional and visually engaging.
                                        <br />We are committed to excellence and integrity, ensuring every project drives success for our clients.
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="corevalue mt-5 p-4">
                        <p className="corevaluehead">
                            Core Values
                        </p>

                        <div>
                            <Row>
                                <Col className="mt-3" lg={1} xs={3}>
                                    <img src={innovation}
                                        alt="innovation"
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <p className="innovation">Innovation</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <p className="innovationtext">We are committed to staying ahead of the curve by embracing new technologies and creative approaches.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3" lg={1} xs={3}>
                                    <img src={quality}
                                        alt="quality"
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <p className="quality">Quality</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <p className="qualitytext">We strive for excellence in everything we do, ensuring our solutions are reliable, efficient, and user-friendly.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3" lg={1} xs={3}>
                                    <img src={integrity}
                                        alt="integrity"
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <p className="integrity">Integrity</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <p className="integritytext">We believe in transparency and honesty in all our interactions and business practices.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3" lg={1} xs={3}>
                                    <img src={customerfocus}
                                        alt="customerfocus"
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <p className="customerfocus">Customer Focus</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <p className="customerfocustext">Our clients are at the heart of our operations. We tailor our solutions to meet their specific needs and exceed their expectations.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3" lg={1} xs={3}>
                                    <img src={collaboration}
                                        alt="collaboration"
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <p className="collaboration">Collaboration</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <p className="collaborationtext">We foster a collaborative environment where teamwork and mutual respect are paramount.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center mt-5">
                        {/* <p className="ourteam">Founders */}
                        {/* <span className="team">Team</span> */}
                        {/* </p> */}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center mt-5">
                        <p className="whyus">Why <span className="chooseus">Choose Us</span></p>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="experiencedteammaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="experiencedteamcard text-center">
                                        <p className="experiencedteam mt-3">Experienced Team</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="experiencedteamtext p-4">
                                Our team comprises seasoned professionals with extensive experience in software development, design, and project management.
                            </p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="customsolnmaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="customsolncard text-center">
                                        <p className="customsoln mt-3">Custom Solutions</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="customsolntext p-4">
                                We tailor our services to meet the unique needs of each client, ensuring personalized and effective solutions.
                            </p>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="innoappmaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="innoappcard text-center">
                                        <p className="innoapp mt-3">Innovation Approach</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="innoapptext p-4">
                                We leverage the latest technologies and innovative methodologies to deliver cutting-edge solutions that give our clients a competitive edge.
                            </p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="qlyassmaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="qlyasscard text-center">
                                        <p className="qlyass mt-3">Quality Assurance</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="qlyasstext p-4">
                                Our rigorous quality assurance processes ensure that our solutions are reliable, secure, and perform optimally.
                            </p>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="endsermaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="endsercard text-center">
                                        <p className="endser mt-3">End-to-End Services</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="endsertext p-4">
                                From initial consultation to deployment and support, we offer comprehensive services to manage every aspect of your project.
                            </p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={6} xs={12}>
                        <Card className="ccmaincard">
                            <Row>
                                <Col lg={4} xs={7}>
                                    <Card className="cccard text-center">
                                        <p className="cc mt-3">Customer-Centric</p>
                                    </Card>
                                </Col>
                                <Col></Col>
                            </Row>
                            <p className="cctext p-4">
                                We prioritize our clients' needs and work closely with them to ensure their satisfaction and success.
                            </p>
                        </Card>
                    </Col>
                </Row>

                {/* ----------------------Footer---------------------- */}

                <MDBFooter bgColor='white' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Deepa Sphere Solutions
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top companylogo"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/" className='text-reset footer-navi-link'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/About" className='text-reset footer-navi-link'>
                                                About
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Services" className='text-reset footer-navi-link'>
                                                Services
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="p-0 footer-navi-btn">
                                            <Link to="/Work" className='text-reset footer-navi-link'>
                                                Work
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:8591188706">
                                                <FiPhoneCall className="me-3" /> +91 85911-88706
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="mailto:deepaspheresolutions@gmail.com">
                                                <MdMailOutline className="me-3" /> deepaspheresolutions@gmail.com
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>Connect with us</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div>
                                            <a href="https://wa.me/918591188706/?text=I%20am%20interested%20in%20the%20services%20your%20company%20provide">
                                                <FaWhatsapp fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaInstagram fontSize={30} className="mx-2" />
                                            </a>

                                            <a href="as">
                                                <FaLinkedin fontSize={30} className="mx-2" />
                                            </a>
                                        </div>
                                    </p>

                                    {/* <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="">
                                                <FaInstagram />
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                                        <div>
                                            <a href="tel:9324053215">
                                                <FaLinkedin />
                                            </a>
                                        </div>
                                    </p> */}
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                </MDBFooter>
            </Container >
        </>
    );
}

export default About;