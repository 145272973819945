import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import About from './About';
import Services from './Services';
import Work from './Work';
import Contactus from './Contactus';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Services' element={<Services />} />
          <Route path='/Work' element={<Work />} />
          <Route path='/Contactus' element={<Contactus />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
